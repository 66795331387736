<template>
  <v-card class="elevation-1">
    <!-- delete dialog -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogDelete" persistent max-width="390">
          <v-card>
            <v-card-title class="text-h5 secondary white--text">
              حذف المنتج
            </v-card-title>
            <v-card-text class="mt-5 text-h5 dark--text"
              ><b> هل أنت متأكد من عملية الحذف </b></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="dialogDelete = false"
              >
                غلق
              </v-btn>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="deleteProduct()"
              >
                تأكيد الحذف
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- images dialog -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogImages" persistent max-width="900">
          <v-card>
            <v-card-title class="text-h5 secondary white--text">
              صور المنتج
            </v-card-title>
            <v-card-text class="mt-5 text-h5 dark--text">
              <v-row>
                <v-col
                  v-for="(data, index) in item.images"
                  :key="index"
                  cols="12"
                  sm="3"
                >
                  <v-btn
                    v-if="item.images.length > 1"
                    @click="deleteImage(data)"
                    class="imgDelete"
                    icon
                    color="red"
                  >
                    <i class="fa fa-close fa-lg" aria-hidden="true"></i>
                  </v-btn>
                  <a :href="server + data.image">
                    <img
                      :src="server + data.image"
                      alt="image"
                      width="150px"
                      height="150px"
                      class="img"
                    /> </a
                ></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="dialogImages = false"
              >
                غلق
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- info dialog -->
    <!-- <template>
      <v-row justify="center">
        <v-dialog v-model="dialogInfo" persistent max-width="600">
          <v-card>
            <v-card-title>التفاصيل </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px; width: 500px" class="mt-3">
              <table id="customers">
                <tr>
                  <th>التفاصيل</th>
                  <th>القيمة</th>
                </tr>
                <tr v-for="(data, index) in this.info" :key="index">
                  <th>
                    <span
                      v-for="(objKey, indexkey) in Object.keys(data)"
                      :key="indexkey"
                    >
                      {{ objKey }} =>{{ data[objKey] }} <br
                    /></span>
                  </th>
                  <th>
                    <v-btn color="red" icon dark @click="delete_detail(index)">
                      <i class="fa fa-close fa-lg" aria-hidden="true"></i>
                    </v-btn>
                  </th>
                </tr>
              </table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions dir="ltr">
              <v-btn
                class="primary"
                color="white darken-1"
                text
                @click="dialogInfo = false"
              >
                غلق
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template> -->
    <v-data-table
      :headers="headers"
      :items="products"
      :options.sync="pagination"
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      :loading="table_loading || false"
      hide-default-footer
      loading-text="جاري التحميل يرجى الأنتظار"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>جدول المنتجات</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="productQuery"
            @input="queryChange"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
            class="mr-5"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }">
        <tr @dblclick="selectedRaw(item)">
          <td class="text-start">{{ item.name }}</td>
          <td class="text-start">{{ item.category.name }}</td>
          <td class="text-start">{{ item.brand.name }}</td>
          <td class="text-start">{{ item.price }}</td>
          <td class="text-start">{{ item.desc }}</td>
          <td class="text-start">
            <v-btn dark icon color="red" @click="getItem(item, 'delete')">
              <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i>
            </v-btn>
            <v-btn dark icon color="info" @click="getItem(item, 'images')">
              <i class="fa fa-picture-o fa-lg" aria-hidden="true"></i>
            </v-btn>
            <!-- <v-btn
              :disabled="item.advance_details != null ? false : true"
              dark
              icon
              color="yellow"
              @click="getItem(item, 'info')"
            >
              <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>
            </v-btn> -->
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row>
        <v-col offset="4" cols="2">
          <v-select
            v-model="pagination.itemsPerPage"
            :items="items"
            label="عدد العناصر في الصفحة"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-pagination
            v-model="pagination.page"
            :length="pageCount"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "اسم المنتج",
          value: "name",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "القسم",
          value: "category",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "الماركة",
          value: "brand",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "السعر",
          value: "price",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "الوصف",
          value: "desc",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "العمليات",
          class: "secondary white--text title",
          sortable: false,
        },
      ],
      item: {},
      dialogDelete: false,
      dialogImages: false,
      dialogInfo: false,
      pagination: {},
      info: [],
      advance_details_info: {},
      items: [5, 10, 25, 50, 100],
    };
  },
  computed: {
    server() {
      return this.$store.state.server;
    },

    productQuery: {
      set(val) {
        this.$store.state.ProductsMoudle.productQuery = val;
      },
      get() {
        return this.$store.state.ProductsMoudle.productQuery;
      },
    },
    pageCount: function () {
      return this.$store.state.ProductsMoudle.pageCount;
    },
    totalItems: function () {
      return this.$store.state.ProductsMoudle.products.length;
    },

    products() {
      return this.$store.state.ProductsMoudle.products;
    },
    table_loading() {
      return this.$store.state.ProductsMoudle.table_loading;
    },
    product_params: {
      set(val) {
        this.$store.state.ProductsMoudle.params = val;
      },
      get() {
        return this.$store.state.ProductsMoudle.params;
      },
    },
    // advance() {
    //   let data = this.item.advance_details;
    //   Object.assign(this.advance_details_info, JSON.parse(data));
    //   console.log(JSON.parse(data));
    //   return this.advance_details_info;
    // },
    //
  },

  methods: {
    delete_detail(index) {
      console.log("here");

      let data = {};
      data["id"] = this.item.id;
      data["index"] = index;
      console.log(data);
      this.$store.dispatch("ProductsMoudle/deleteAdvanceDetails", data);
      // this.info.splice(index, 1);
      this.dialogInfo = false;
      // location.reload();
    },
    selectedRaw(item) {
      console.log(item);
      this.$store.state.ProductsMoudle.selected_object = {};
      Object.assign(this.$store.state.ProductsMoudle.selected_object, item);
      this.$store.state.ProductsMoudle.isEdit = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    queryChange(val) {
      this.searchDebounce();
    },
    getItem(item, type) {
      this.item = item;
      console.log(JSON.parse(item.advance_details));
      if (type == "delete") this.dialogDelete = true;
      else if (type == "images") this.dialogImages = true;
      else if (type == "info") {
        this.dialogInfo = true;
        this.info = JSON.parse(item.advance_details);
        console.log(this.info);
      }
    },
    deleteImage(item) {
      console.log(item);
      let data = {};
      data["id"] = item.id;
      data["product_id"] = item.product_id;
      this.$store.dispatch("ProductsMoudle/deleteImage", data);
      this.dialogImages = false;
    },
    deleteProduct() {
      console.log(this.item);
      this.$store.dispatch("ProductsMoudle/deleteProduct", this.item);
      this.dialogDelete = false;
      this.item = {};
    },
    getProducts() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.product_params = par;
      this.$store.dispatch("ProductsMoudle/getProducts");
    },
    searchDebounce() {
      clearTimeout(this._timerId);
      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.$store.dispatch("ProductsMoudle/resetFields");
        this.pagination.page = 1;
        this.getProducts();
      }, 1000);
    },
  },
  created() {
    this.$store.dispatch("ProductsMoudle/resetFields");
  },
  watch: {
    pagination: {
      handler() {
        this.getProducts();
      },
      deep: true,
    },
  },
};
</script>
<style>
.imgDelete {
  position: absolute;
  top: -70;
  right: 25;
}
.imgDelete:hover {
  width: 50px;
}
</style>
