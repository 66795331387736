<template>
  <v-container>
    <!-- <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="1000px">
          <v-card>
            <v-card-title>التفاصيل </v-card-title>
            <v-divider></v-divider>
            <v-card-text
              style="height: 1000px; width: 900px"
              class="mt-3 mr-10"
            >
              <table id="customers">
                <tr>
                  <th>التفاصيل</th>
                  <th>القيمة</th>
                  <th>الحذف</th>
                </tr>
                <tr
                  v-for="(value, key, index) in input_advance_details"
                  :key="index"
                >
                  <th>{{ key }}</th>
                  <th>{{ value }}</th>
                  <th>
                    <v-btn
                      color="red"
                      icon
                      dark
                      @click="
                        delete_from_input_advance_details(key, index, value)
                      "
                    >
                      <i class="fa fa-close fa-lg" aria-hidden="true"></i>
                    </v-btn>
                  </th>
                </tr>
              </table>
              <template>
                <v-divider></v-divider>
                <span class="hint_advance"><b>التفاصيل المحجوزة</b> </span>
                <table class="mt-5" id="customers">
                  <tr>
                    <th>التفاصيل المحجوزة</th>

                    <th>الحذف</th>
                  </tr>
                  <tr v-for="(data, index) in advance_details" :key="index">
                    <th>
                      <span
                        v-for="(objKey, indexkey) in Object.keys(data)"
                        :key="indexkey"
                      >
                        {{ objKey }} =>{{ data[objKey] }} <br
                      /></span>
                    </th>

                    <th>
                      <v-btn
                        color="red"
                        icon
                        dark
                        @click="delete_detail(index)"
                      >
                        <i class="fa fa-close fa-lg" aria-hidden="true"></i>
                      </v-btn>
                    </th>
                  </tr>
                </table>
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions dir="ltr">
              <v-btn
                class="primary"
                color="white darken-1"
                text
                dark
                @click="dialog = false"
              >
                غلق
              </v-btn>
              <v-btn
                class="primary ml-2"
                color="white darken-1"
                text
                :disabled="
                  Object.keys(input_advance_details).length > 0 ? false : true
                "
                dark
                @click="saveAdvance_details"
              >
                حفظ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template> -->
    <v-form ref="form" class="mr-10">
      <v-col cols="12" md="12" lg="12">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="selected_object.name"
              placeholder="اسم المنتج "
              label="اسم المنتج "
              hide-details="auto"
              :rules="rules"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              clearable
              :items="categories"
              v-model="selected_object.category_id"
              item-text="name"
              item-value="id"
              label=" اختر قسم"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              clearable
              :items="brands"
              v-model="selected_object.brand_id"
              item-text="name"
              item-value="id"
              label=" اختر ماركة"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="selected_object.price"
              placeholder="سعر المنتج "
              label="سعر المنتج "
              hide-details="auto"
              :rules="rules"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-textarea
              class="mx-2"
              label="ادخل وصف المنتج هنا ...."
              rows="1"
              v-model="selected_object.desc"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              v-model="selected_object.offer"
              placeholder="قيمة العرض  "
              label="قيمة العرض  "
              hide-details="auto"
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            v-if="selected_object.offer != null && selected_object.offer != 0"
          >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  v-model="selected_object.offer_expired"
                  label="تأريخ انتهاء العرض "
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hint="يجب ادخال تأريخ انتهاء الخصم بعد تأريخ اليوم"
                ></v-text-field>
                <span class="hint"
                  ># يجب ادخال تأريخ انتهاء الخصم بعد تأريخ اليوم</span
                >
              </template>
              <v-date-picker
                v-model="selected_object.offer_expired"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: center"
            >
              <span class="hint_image">اختر صور المنتج :</span>
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                maxImageSize="5"
              ></vue-upload-multiple-image>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="3">
            <v-combobox
              clearable
              label="اسم الوصف "
              small-chips
              :items="items"
              v-model="key"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="value"
              placeholder="اضف القيمة"
              label="اضف القيمة"
              hide-details="auto"
              clearable
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="1">
            <v-btn
              @click="add_details"
              class="d-flex mx-2"
              fab
              dark
              color="secondary"
            >
              <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="1">
            <v-btn fab color="secondary" @click="dialog = true">
              <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
            </v-btn>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" align-self="center" class="text-center">
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn secondary color="secondary" @click="validateField">
                  {{ isEdit ? "تعديل القيد" : "أضافة قيد" }}
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn secondary color="secondary" @click="reset">
                  تصفير الحقول
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </v-container>
</template>
<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Vue from "vue";

export default {
  data() {
    return {
      headers: [
        {
          text: "التفاصيل",
          value: "key",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "القيمة",
          value: "value",
          class: "secondary white--text title",
        },
      ],
      name: "",
      menu: null,
      images: [],
      items: ["اللون", "الحجم"],
      upload: [],
      advance_details: [],
      input_advance_details: {},
      key: "",
      value: "",
      category_id: null,
      rules: [(v) => !!v || "هذا الحقل مطلوب"],
      dialog: false,
    };
  },
  components: {
    VueUploadMultipleImage,
  },
  computed: {
    categories() {
      return this.$store.state.CategoryModule.categories;
    },
    brands() {
      return this.$store.state.BrandModule.brands;
    },
    selected_object() {
      return this.$store.state.ProductsMoudle.selected_object;
    },
    isEdit() {
      return this.$store.state.ProductsMoudle.isEdit;
    },
  },
  methods: {
    delete_from_input_advance_details(key, index, value) {
      const prop = key;
      console.log(this.test);
      Vue.delete(this.input_advance_details, prop); // delete the property from object
      console.log(this.input_advance_details);
    },
    delete_detail(index) {
      this.advance_details.splice(index, 1);
      console.log(index);
    },

    add_details() {
      let data = {};
      data[this.key] = this.value;
      Object.assign(this.input_advance_details, data);
      console.log(this.input_advance_details);

      this.key = "";
      this.value = "";
    },
    saveAdvance_details() {
      this.advance_details.push(this.input_advance_details);
      this.input_advance_details = {};
      this.dialog = false;
      console.log(this.advance_details);
    },
    getCategories() {
      this.$store.dispatch("CategoryModule/getCategories");
    },
    getBrands() {
      this.$store.dispatch("BrandModule/getBrands");
    },
    uploadImageSuccess(formData, index, fileList) {
      this.upload = [];
      fileList.forEach((element) => {
        let img = element.path;
        this.upload.push(img);
      });
      console.log(fileList);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    validateField() {
      if (this.$refs.form.validate()) {
        let data = {};
        data["name"] = this.selected_object.name;
        data["category_id"] = this.selected_object.category_id;
        data["brand_id"] = this.selected_object.brand_id;
        data["price"] = this.selected_object.price;
        data["desc"] = this.selected_object.desc;
        data["advance_details"] = this.advance_details;
        data["images"] = this.upload;
        if (this.selected_object.offer != null) {
          data["offer"] = this.selected_object.offer;
          data["offer_expired"] = this.selected_object.offer_expired;
        }
        console.log(data);

        if (this.isEdit) {
          data["id"] = this.selected_object.id;
          console.log(data);
          this.editProduct(data);
        } else {
          this.addProduct(data);
        }
        this.reset();
      }
    },
    addProduct(data) {
      this.$store.dispatch("ProductsMoudle/addProduct", data);
    },
    editProduct(data) {
      console.log(data);
      this.$store.dispatch("ProductsMoudle/editProduct", data);
      this.reset();
    },
    reset() {
      this.$refs.form.reset();
      this.images = [];
      this.upload = [];
      this.$store.state.ProductsMoudle.isEdit = false;
    },
  },
  created() {
    this.getCategories();
    this.getBrands();
  },
};
</script>
<style>
.image-container[data-v-10e59822] {
  width: 161px !important;
  height: 168px !important;
  border: 1px dashed #d6d6d6;
  border-radius: 10px;
  background-color: #fff;
}
.hint_image {
  position: relative;
  top: 30px;
  padding: 10px;
  color: #ff0000;
  font-weight: bold;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #384c81;
  color: white;
}
.hint_advance {
  position: relative;
  top: 18px;
  right: 150px;
  padding: 10px;
  color: #ff0000;
  font-weight: bold;
}

/* 



 [
    {
        "ss": "aaa",
        "اللون": "xxx"
    },
    {
        "الحجم": "xxx"
    },
      {
        "ss": "aaa",
        "اللون": "xxx"
    },
    {
        "الحجم": "xxx"
    }
]
*/
</style>
