<template>
  <v-card class="elevation-1">
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="390">
          <v-card>
            <v-card-title class="text-h5 secondary white--text">
              حذف القسم
            </v-card-title>
            <v-card-text class="mt-5 text-h5 dark--text"
              ><b> هل أنت متأكد من عملية الحذف </b></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="dialog = false"
              >
                غلق
              </v-btn>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="deleteCategory()"
              >
                تأكيد الحذف
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="categories"
      :options.sync="pagination"
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      :loading="table_loading || false"
      hide-default-footer
      loading-text="جاري التحميل يرجى الأنتظار"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>جدول الاقسام</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="categoryQuery"
            @input="queryChange"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
            class="mr-5"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }">
        <tr @dblclick="selectedRaw(item)">
          <td class="text-start">{{ item.name }}</td>
          <td class="text-start">
            <img
              :src="server + item.icone"
              alt="image"
              width="50px"
              height="50px"
            />
          </td>

          <td class="text-start" v-if="item.parent_id != null">
            <span class="hint_category"
              ><v-chip color="blue"><b>قسم فرعي</b></v-chip></span
            >
          </td>
          <td class="text-start" v-else>
            <span class="hint_category"
              ><v-chip color="green"><b>قسم رئيسي</b></v-chip></span
            >
          </td>
          <td class="text-center" v-if="item.children_count > 0">
            <span class="children_count">
              <b> {{ item.children_count }} </b></span
            >
          </td>
          <td class="text-start" v-else>
            <span class="hint_category"
              ><v-chip color="red"><b>لايوجد اقسام فرعية</b></v-chip></span
            >
          </td>

          <td class="text-start">
            <v-btn dark color="red" @click="getItem(item)">حذف </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row>
        <v-col offset="4" cols="2">
          <v-select
            v-model="pagination.itemsPerPage"
            :items="items"
            label="عدد العناصر في الصفحة"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-pagination
            v-model="pagination.page"
            :length="pageCount"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "اسم القسم",
          value: "name",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "صورة القسم",
          value: "icone",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "النوع",
          value: "parent_id",
          class: "secondary white--text title",
          sortable: true,
        },
        {
          text: "الاقسام الفرعية",
          value: "children_count",
          class: "secondary white--text title",
          sortable: true,
        },
        {
          text: "الحذف",
          class: "secondary white--text title",
          sortable: false,
        },
      ],
      item: {},
      dialog: false,
      pagination: {},
      items: [5, 10, 25, 50, 100],
    };
  },
  computed: {
    server() {
      return this.$store.state.server;
    },

    categoryQuery: {
      set(val) {
        this.$store.state.CategoryModule.categoryQuery = val;
      },
      get() {
        return this.$store.state.CategoryModule.categoryQuery;
      },
    },
    pageCount: function () {
      return this.$store.state.CategoryModule.pageCount;
    },
    totalItems: function () {
      return this.$store.state.CategoryModule.categories.length;
    },

    categories() {
      return this.$store.state.CategoryModule.categories;
    },
    table_loading() {
      return this.$store.state.CategoryModule.table_loading;
    },
    category_params: {
      set(val) {
        this.$store.state.CategoryModule.params = val;
      },
      get() {
        return this.$store.state.CategoryModule.params;
      },
    },
  },
  methods: {
    // scrollToTop: function () {
    //   document
    //     .getElementById("scroll-to-top")
    //     .scrollIntoView({ behavior: "smooth" });
    // },
    selectedRaw(item) {
      console.log(item);
      this.$store.state.CategoryModule.selected_object = {};
      Object.assign(this.$store.state.CategoryModule.selected_object, item);
      this.$store.state.CategoryModule.isEdit = true;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    queryChange(val) {
      this.searchDebounce();
    },
    getItem(item) {
      this.dialog = true;
      this.item = item;
    },

    deleteCategory() {
      console.log(this.item);
      this.$store.dispatch("CategoryModule/deleteCategory", this.item);
      this.dialog = false;
      this.item = {};
    },
    getCategories() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.category_params = par;
      this.$store.dispatch("CategoryModule/getCategories");
    },
    searchDebounce() {
      clearTimeout(this._timerId);
      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.$store.dispatch("CategoryModule/resetFields");
        this.pagination.page = 1;
        this.getCategories();
      }, 1000);
    },
  },
  created() {
    this.$store.dispatch("CategoryModule/resetFields");
  },
  watch: {
    pagination: {
      handler() {
        this.getCategories();
      },
      deep: true,
    },
  },
};
</script>
<style>
.hint_category {
  color: #f44336;
  font-size: 12px;
  font-weight: bold;
}
.children_count {
  font-size: 24px;
  margin-left: 125px !important;
}
</style>
