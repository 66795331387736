<template>
  <div>
    <v-app-bar
      color="secondary"
      app
      dark
      elevation="4"
      v-if="!$route.path.includes('login')"
    >
      <!-- this app  عملها تخلي الناف بار فوق سايد بار يعني تخلي عنصر هو اول شي  -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-toolbar-title>MEDO STORE</v-toolbar-title> -->
    </v-app-bar>

    <v-navigation-drawer
      :clipped="clipped"
      v-model="drawer"
      nable-resize-watcher
      app
      dark
      right
      color="secondary"
      v-if="!$route.path.includes('login')"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <!-- <img src="/images/FullLogo.png" /> -->
          </v-list-item-avatar>

          <v-list-item-content>
            <!-- <v-list-item-title>ali luay</v-list-item-title> -->
            <!-- <v-list-item-subtitle style="white-space: pre-line"
              >Medo Store
            </v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item-group v-model="group" active-class=" text--accent-4 ">
          <v-list-item to="/">
            <v-list-item-icon>
              <i class="fa fa-meetup" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الصفحة الرئيسية</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/categories">
            <v-list-item-icon>
              <i class="fa fa-meetup" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الاقسام</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/brands">
            <v-list-item-icon>
              <i class="fa fa-meetup" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الماركات</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/coupons">
            <v-list-item-icon>
              <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الخصومات</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/ads">
            <v-list-item-icon>
              <i class="fa fa-adn" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الاعلانات</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/products">
            <v-list-item-icon>
              <i class="fa fa-adn" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>المنتجات</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/orders">
            <v-list-item-icon>
              <i class="fa fa-adn" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الطلبات</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/users">
            <v-list-item-icon>
              <i class="fa fa-user" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>المستخدمين</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/admins">
            <v-list-item-icon>
              <i class="fa fa-user" aria-hidden="true"></i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>الادمنية</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>تسجيل خروج</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data: () => ({
    drawer: true,
    group: null,
    clipped: false,
  }),

  methods: {
    signOut: function () {
      this.$store.dispatch("resetFields");
      this.user_type = -1;
      console.log("in log out ");
      localStorage.removeItem("token");
      localStorage.removeItem("user_name");
      this.$store.dispatch("logout").then(() => {
        this.$router.replace("/login");
      });
    },
  },

  //  هاي تخلي الدرور يختفي عند الضغط على اي زر تريدة
  // watch: {
  //   group() {
  //     this.drawer = false;
  //   },
  // },
};
</script>

<style></style>
