import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";


import CategoryModule from "./modules/CategoryModule";
import BrandModule from "./modules/BrandModule";
import CouponModule from "./modules/CouponModule";
import ProductsMoudle from "./modules/ProductsMoudle";
import AdsMoudle from "./modules/AdsMoudle";
import UserModule from "./modules/UserModule";
import OrderMoudle from "./modules/OrderMoudle";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // server: "http://64.225.111.42:8009",
    server: "https://app.ghaith-art.com/public",
    snackbar: false,
    textSnackbar: "",
    isLoggedIn: false,
    token: localStorage.token,
    status: "",
    statistics: [],
  },
  getters: {
    isLoggedIn: (state) => !!state.token,

  },
  mutations: {

    auth_success(state, token) {
      state.token = token;
      state.status = "done";
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_error(state) {
      state.status = "error";
    },
    statistics_request(state, statistics) {
      state.statistics.push(statistics)
    }
  },
  actions: {
    async resetFields({ state }) {
      state.token = null;
      state.isLoggedIn = false;
      state.status = "";
      state.user_type = -1;


    },
    snackbarToggle: function ({ state }, data) {
      if (data.toggle == false) {
        state.snackbar = data.toggle;
      } else {
        state.snackbar = data.toggle;
        state.textSnackbar = data.text;
      }
    },
    login({ commit, state }, data) {
      // console.log(data);
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: `${state.server}` + "/api/login",
          data: data,
          method: "POST",
        })
          .then((resp) => {
            // console.log(resp);
            const token = resp.data.token;
            const user_type = resp.data.result[0].user_type;
            const user_name = resp.data.result[0].user_name;



            localStorage.setItem("token", token);
            localStorage.setItem("user_type", user_type);
            localStorage.setItem("full_name", user_name);

            Vue.prototype.$http.defaults.headers.common["Authorization"] =
              "Bearer " + token;
            commit("auth_success", token);
            resolve(resp);
          })
          .catch((err) => {
            // console.log(err);
            commit("auth_error");
            localStorage.removeItem("token");

            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_type");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    getStatistics({ commit, state }) {
      axios({
        url: `${state.server}` + "/api/statistics",
        method: "GET",
      })
        .then((resp) => {
          console.log(resp);
          commit("statistics_request", resp.data.result);
          resolve(resp);
        })
        .catch((err) => {

          reject(err);
        });
    }
  },
  modules: {
    CategoryModule,
    BrandModule,
    CouponModule,
    ProductsMoudle,
    AdsMoudle,
    UserModule,
    OrderMoudle
  },
});
