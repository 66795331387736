<template>
  <v-card class="elevation-1">
    <!-- show products  -->

    <!-- <template v-if="Object.keys(item).length > 0">
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="990">
          <v-card>
            <v-card-title class="text-h5 secondary white--text">
              المنتجات الخاصة بهذا الطلب
            </v-card-title>
            <v-card-text class="mt-5 text-h5 dark--text">
              <v-layout>
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    v-for="(item, index) in item.products"
                    :key="index"
                  >
                    <v-card style="min-height: 300px">
                      <v-img
                        contain
                        class="white--text"
                        height="150px"
                        :src="server + item.images[0].image"
                      >
                      </v-img>
                      <v-card-title>
                        <div>
                          <span class="title blue--text"> {{ item.name }} </span
                          ><br />
                          <span class="title">
                            {{ item.price }}
                          </span>
                          <del
                            v-if="item.offer != null"
                            class=""
                            style="color: red"
                          >
                            {{ (item.price * item.offer) / 100 - item.price }}
                          </del>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="secondary"
                color="white darken-1"
                text
                @click="dialog = false"
              >
                غلق
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template> -->

    <template>
      <v-container>
        <v-row justify="center" dir="rtl">
          <v-dialog v-model="dialog" scrollable max-width="900px" dir="rtl">
            <v-card>
              <v-card-title class="text-h5 secondary white--text">
                تأكيد الطلب
              </v-card-title>
              <v-card-text id="printMe" dir="rtl">
                <v-row dir="rtl">
                  <v-col sm="12" md="12" lg="12" justify="center" dir="rtl">
                    <div class="title text-center" justify="center" dir="rtl">
                      <h2
                        style="
                          margin: 0 auto;
                          border-top: 1px dashed #bbb;
                          padding: 10px;
                          text-align: center;
                          letter-spacing: 2px;
                        "
                      >
                        شركة اعمدة الشموخ للمقاولات العامة المحدودة
                      </h2>
                      <h2
                        style="
                          margin: 0 auto;
                          padding: 10px;
                          text-align: center;
                          letter-spacing: 2px;
                        "
                      >
                        وتجهيز الكونكريت
                      </h2>
                    </div>
                    <div class="numbers" justify="center">
                      <p
                        style="
                          margin: 0 auto;
                          padding: 10px;
                          text-align: center;
                        "
                      >
                        <b>07711119970 - 07811119970 - 07705333603</b>
                      </p>
                    </div>
                    <hr />
                    <div>
                      <p
                        style="margin: 0 auto; padding: 10px; text-align: right"
                      >
                        <span style="padding-left: 30px">
                          <b>رقم الطلب : {{ serial_number }}</b>
                        </span>

                        <span style="padding-left: 30px"
                          ><b
                            >تأريخ الشراء :
                            {{ created_at | moment("DD-MM-YYYY") }}
                          </b></span
                        >
                        <span style="padding-left: 75px"
                          ><b
                            >تأريخ طباعة الفاتورة :
                            {{ date_now }}
                          </b></span
                        >
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-data-table
                    :headers="headers_order"
                    :items="products"
                    hide-default-footer
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-start">{{ item.name }}</td>
                        <td class="text-start">{{ item.price }}</td>
                        <td class="text-start">{{ item.code_product }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-row>
                <v-row dir="rtl">
                  <v-col sm="12" md="12" lg="12" justify="center" dir="rtl">
                    <div>
                      <p
                        style="margin: 0 auto; padding: 10px; text-align: right"
                      >
                        <span style="padding-left: 30px">
                          <b>أسم المستخدم : {{ order_user }}</b>
                        </span>

                        <span style="padding-left: 30px"
                          ><b
                            >السعر النهائي :
                            {{ total_cost }}
                          </b></span
                        >
                        <span style="padding-left: 75px"
                          ><b
                            >كود الخصم :
                            {{ coupon_code }}
                          </b></span
                        >
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-col cols="auto">
                  <v-btn secondary color="secondary" v-print="'#printMe'">
                    طباعة
                  </v-btn>
                </v-col>
                <v-col cols="auto">
                  <v-btn secondary color="secondary" @click="dialog = false">
                    غلق
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </template>

    <v-data-table
      :headers="headers"
      :items="orders"
      :options.sync="pagination"
      :page.sync="pagination.page"
      :items-per-page="pagination.itemsPerPage"
      :loading="table_loading || false"
      hide-default-footer
      loading-text="جاري التحميل يرجى الأنتظار"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>جدول الطلبات</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="orderQuery"
            @input="queryChange"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
            class="mr-5"
          ></v-text-field>
        </v-toolbar>
      </template>
      <th
        v-for="header in headers"
        :key="header.text"
        :class="[
          'column sortable',
          pagination.descending ? 'desc' : 'asc',
          header.value === pagination.sortBy ? 'active' : '',
        ]"
        @click="changeSort(header.value)"
      >
        <v-icon small>arrow_upward</v-icon>
        {{ header.text }}
      </th>
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-start">
            <v-chip color="yellow" v-if="item.status == 0"
              >الطلب في حالة الانتضار</v-chip
            >
            <v-chip dark color="info" v-if="item.status == 1"
              >تم تحويل الطلب الى شركة التوصيل</v-chip
            >
            <v-chip dark color="green" v-if="item.status == 2"
              >تم تسليم الطلب</v-chip
            >
            <v-chip dark color="red" v-if="item.status == 3"
              >تم رفض الطلب</v-chip
            >
          </td>
          <td class="text-start">{{ item.serial_number }}</td>
          <td class="text-start">{{ item.user.user_name }}</td>
          <td class="text-start">{{ item.phone_number }}</td>
          <td class="text-start">{{ item.address }}</td>
          <td class="text-start" v-if="item.coupon">
            {{ item.coupon.coupon_code }}
          </td>
          <td class="text-start" v-else>لايوجد كوبون</td>
          <td class="text-start">{{ item.total_cost }}</td>

          <td class="text-start">
            <span>{{ item.created_at | moment("DD,MM,YYYY") }}</span>
          </td>

          <td class="text-start">
            <v-btn dark color="primary" @click="getProduct(item)"
              >عرض المنتجات</v-btn
            >
          </td>
          <td class="text-start">
            <v-btn dark color="grey" @click="print(item)">طباعة</v-btn>
          </td>

          <td
            class="text-start"
            style="display: flex; flex-diractions: row"
            v-if="item.status == 0"
          >
            <v-btn
              dark
              color="red"
              style="margin-left: 5px; margin-top: 5px"
              @click="changeOrderStatus(item, (type = 3))"
            >
              الغاء الطلب</v-btn
            >
            <v-btn
              dark
              color="info"
              style="margin-left: 5px; margin-top: 5px"
              @click="changeOrderStatus(item, (type = 1))"
            >
              تحويل الى شركة التوصيل</v-btn
            >
          </td>
          <td
            class="text-start"
            style="display: flex; flex-diractions: row"
            v-if="item.status == 1"
          >
            <v-btn
              dark
              color="red"
              style="margin-left: 5px; margin-top: 5px"
              @click="changeOrderStatus(item, (type = 3))"
            >
              الغاء الطلب</v-btn
            >
            <v-btn
              dark
              color="green"
              style="margin-left: 5px; margin-top: 5px"
              @click="changeOrderStatus(item, (type = 2))"
            >
              انهاء الطلب</v-btn
            >
          </td>
          <td
            class="text-start"
            style="display: flex; flex-diractions: row"
            v-if="item.status == 2"
          >
            <v-btn
              dark
              color="primary"
              style="margin-left: 5px; margin-top: 5px"
            >
              تم تسليم الطلب</v-btn
            >
          </td>
          <td
            class="text-start"
            style="display: flex; flex-diractions: row"
            v-if="item.status == 3"
          >
            <v-btn
              dark
              color="yellow"
              style="margin-left: 5px; margin-top: 5px"
            >
              تم الغاء الطلب</v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-row>
        <v-col offset="4" cols="2">
          <v-select
            v-model="pagination.itemsPerPage"
            :items="items"
            label="عدد العناصر في الصفحة"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-pagination
            v-model="pagination.page"
            :length="pageCount"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "حالة الطلب ",
          value: "status",
          class: "secondary white--text title",
        },
        {
          text: "رقم الطلب",
          value: "serial_number",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "اسم المستخدم",
          value: "user name",
          class: "secondary white--text title",
          sortable: false,
        },
        {
          text: "رقم الهاتف",
          value: "phone_number",
          class: "secondary white--text title",
        },
        {
          text: "العنوان ",
          value: "address",
          class: "secondary white--text title",
        },
        {
          text: "الخصم",
          value: "coupon",
          class: "secondary white--text title",
        },
        {
          text: "مجموع الطلب",
          value: "total_cost",
          class: "secondary white--text title",
        },
        {
          text: "تأريخ الشراء",
          value: "created_at",
          class: "secondary white--text title",
        },
        {
          text: "المنتجات",
          value: "products",
          class: "secondary white--text title",
        },
        {
          text: "طباعة",
          value: "print",
          class: "secondary white--text title",
        },

        {
          text: "العمليات",
          class: "secondary white--text title",
          sortable: false,
        },
      ],
      headers_order: [
        {
          text: "أسم المنتج",
          value: "product_name",
        },
        {
          text: "سعر المنتج",
          value: "product_price",
        },
        {
          text: "كود المنتج",
          value: "product_code",
        },
      ],
      item: {},
      dialog: false,
      pagination: {},
      items: [5, 10, 25, 50, 100],
      total_cost: "",
      products: [],
      order_user: "",
      coupon: "",
      created_at: "",
      serial_number: "",
      date_now: "",
      printObj: {
        id: "print_me",
        popTitle: "طباعة سند قبض/صرف",
        extraHead: '<meta http-equiv="Content-Language"content="en-ar"/>',
      },
    };
  },
  computed: {
    server() {
      return this.$store.state.server;
    },
    orderQuery: {
      set(val) {
        this.$store.state.OrderMoudle.orderQuery = val;
      },
      get() {
        return this.$store.state.OrderMoudle.orderQuery;
      },
    },
    pageCount: function () {
      return this.$store.state.OrderMoudle.pageCount;
    },
    totalItems: function () {
      return this.$store.state.OrderMoudle.orders.length;
    },

    orders() {
      return this.$store.state.OrderMoudle.orders;
    },
    table_loading() {
      return this.$store.state.OrderMoudle.table_loading;
    },
    order_params: {
      set(val) {
        this.$store.state.OrderMoudle.params = val;
      },
      get() {
        return this.$store.state.OrderMoudle.params;
      },
    },
  },
  methods: {
    print(item) {
      console.log(item);
      this.dialog = true;
      this.total_cost = item.total_cost;
      this.serial_number = item.serial_number;
      item.products.forEach((element) => {
        this.products.push(element);
      });
      this.order_user = item.user.user_name;
      this.coupon = item.coupon.coupon_code;
      this.created_at = item.created_at;

      const current = new Date();
      const moment = require("moment");
      this.date_now = moment(current).format("DD-MM-YYYY");
    },

    getProduct(item) {
      console.log(item);
      this.item = item;
      this.dialog = true;
    },
    changeOrderStatus(item, type) {
      console.log(item);
      console.log(type);
      let data = {};
      data["id"] = item.id;
      data["status"] = type;
      this.$store.dispatch("OrderMoudle/changeOrderStatus", data);
    },
    queryChange(val) {
      this.searchDebounce();
    },

    getOrders() {
      let pagination = this.pagination;
      let par = {
        ...pagination,
      };
      this.order_params = par;
      this.$store.dispatch("OrderMoudle/getOrders");
    },
    searchDebounce() {
      clearTimeout(this._timerId);
      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.$store.dispatch("OrderMoudle/resetFields");
        this.pagination.page = 1;
        this.getOrders();
      }, 1000);
    },
    changeSort(column) {
      let pagination = this.users_params;
      if (pagination.sortBy[0] === column) {
        if (pagination.sortDesc[0] === true) {
          pagination.sortBy = [];
          pagination.sortDesc = [];
        } else {
          pagination.sortDesc = [true];
        }
      } else {
        pagination.sortBy = [column];
        pagination.sortDesc = [false];
      }
      this.$store.dispatch("OrderMoudle/resetFields");
      this.users_params.page = 1;
      this.users_params.sortBy = pagination.sortBy;
      this.users_params.sortDesc = pagination.sortDesc;
    },
  },
  created() {
    this.$store.dispatch("OrderMoudle/resetFields");
  },
  watch: {
    pagination: {
      handler() {
        this.getOrders();
      },
      deep: true,
    },
  },
  // to work the sorting functions must install material icone
};
</script>
<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
