<template>
  <v-container>
    <v-card class="mb-5">
      <v-toolbar dark color="secondary">
        <v-toolbar-title align-center>اضافة قسم جديد</v-toolbar-title>
      </v-toolbar>
      <v-card-text> <categoryForm></categoryForm></v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-card class="mt-5"> <categoriesTable></categoriesTable> </v-card>
    <v-divider></v-divider>
  </v-container>
</template>
<script>
import categoryForm from "../components/forms/categoryForm.vue";
import categoriesTable from "../components/tables/categoriesTable.vue";
export default {
  components: {
    categoryForm,
    categoriesTable,
  },
};
</script>
